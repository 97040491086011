<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form class="d-flex flex-column flex-md-row justify-md-space-between">
               <div class="pa-2 d-flex flex-column justify-start" style="width: 100%">
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.nombre.$touch();
                        }
                     "
                     v-model="nombre"
                     label="Nombre"
                     :error-messages="nombreErrors"
                     required
                     @blur="$v.nombre.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.username.$touch();
                        }
                     "
                     v-model="username"
                     :disabled="isEdit"
                     label="Username"
                     :error-messages="usernameErrors"
                     required
                     @blur="$v.username.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.password.$touch();
                        }
                     "
                     v-model="password"
                     label="Contraseña"
                     :error-messages="passwordErrors"
                     :required="!isEdit"
                     @blur="$v.password.$touch()"
                     :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                     :type="show_password ? 'text' : 'password'"
                     @click:append="show_password = !show_password"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.telefono.$touch();
                        }
                     "
                     v-model="telefono"
                     label="Telefono"
                     :error-messages="telefonoErrors"
                     required
                     @blur="$v.telefono.$touch()"
                  ></v-text-field>
               </div>
               <div class="pa-2 d-flex flex-column justify-start" style="width: 100%">
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.rfc.$touch();
                        }
                     "
                     v-model="rfc"
                     label="RFC"
                     :error-messages="rfcErrors"
                     required
                     @blur="$v.rfc.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.email.$touch();
                        }
                     "
                     v-model="email"
                     label="Email"
                     :error-messages="emailErrors"
                     required
                     @blur="$v.email.$touch()"
                  ></v-text-field>
                  <div class="d-flex align-center justify-space-around">
                     <v-text-field
                        class="mr-2"
                        v-model="articulo_id"
                        :error-messages="articulo_idErrors"
                        label="Liga"
                        type="number"
                        requied
                        @input="
                           () => {
                              input();
                              $v.articulo_id.$touch();
                           }
                        "
                        @blur="$v.articulo_id.$touch()"
                     ></v-text-field>
                     <v-switch @input="input" v-model="estatus" true-value="S" false-value="N" label="Estatus" :disabled="!isEdit"></v-switch>
                  </div>
                  <v-select
                     item-text="text"
                     item-value="value"
                     v-model="rol"
                     :return-object="true"
                     :items="roles"
                     @input="
                        () => {
                           input();
                           $v.rol.$touch();
                        }
                     "
                     label="Rol"
                     :error-messages="rolErrors"
                     required
                     @blur="$v.rol.$touch()"
                  ></v-select>
               </div>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, minLength, integer, alphaNum } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         name: function () {
            return /^[a-zA-Z_ ]*$/.test(this.nombre);
         },
      },
      username: {
         alphaNum,
         required,
         maxLength: maxLength(50),
         minLength: minLength(10),
      },
      password: {
         required: function () {
            if (this.isEdit) {
               return true;
            } else if (this.password.trim().length > 0) {
               return true;
            } else {
               return false;
            }
         },
         minLength: minLength(10),
         maxLength: maxLength(30),
      },
      telefono: {
         required,
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
      rfc: { required },
      email: { required, email, maxLength: maxLength(320) },
      rol: {
         required: function () {
            return (this.rol.value + "").length > 0;
         },
      },
      articulo_id: {
         required: function () {
            if (this.$store.getters.isMicrosip) {
               if (this.articulo_id) {
                  return true;
               } else {
                  return false;
               }
            } else {
               return true;
            }
         },
         is_integer: function () {
            return /^-?[0-9]+$/.test(this.articulo_id) || !this.$store.getters.isMicrosip;
         },
         isNumber: function () {
            return !isNaN(this.articulo_id) || !this.$store.getters.isMicrosip;
         },
         absNum: function () {
            return this.articulo_id >= 0 || !this.$store.getters.isMicrosip;
         },
      },
   },
   props: {
      value: Object,
      title: String,
   },
   model: {
      prop: "value",
      event: "input",
   },
   data: function () {
      return {
         id: this.value.id,
         username: this.value.username,
         nombre: this.value.nombre,
         telefono: this.value.telefono,
         rfc: this.value.rfc,
         email: this.value.email,
         estatus: this.value.estatus,
         articulo_id: this.value.articulo_id,
         rol: {
            value: "",
            text: "",
         },
         password: "",
         dialog: false,
         show_password: false,
         isloading: false,
         roles: [],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertUser", {
                     username: this.username,
                     nombre: this.nombre,
                     telefono: this.telefono,
                     rfc: this.rfc,
                     email: this.email,
                     pass: this.password,
                     id_rol: this.rol.value,
                     articulo_id: this.articulo_id,
                  });
               } else {
                  res = await this.$store.dispatch("updateUser", {
                     id: this.id,
                     nombre: this.nombre,
                     telefono: this.telefono,
                     rfc: this.rfc,
                     email: this.email,
                     estatus: this.estatus,
                     articulo_id: this.articulo_id,
                     pass: this.password,
                     id_rol: this.rol.value,
                  });
               }
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.id = i.id;
         this.username = i.username;
         this.nombre = i.nombre;
         this.telefono = i.telefono;
         this.rfc = i.rfc;
         this.email = i.email;
         this.estatus = !i.id ? "S" : i.estatus;
         this.articulo_id = i.articulo_id;
         this.password = "";
         this.dialog = true;
         let rols = [];
         this.$store.state.roles.forEach(function (val, index, arr) {
            rols.push({
               value: val.id_rol,
               text: val.nombre,
            });
         });
         this.roles = rols;
         this.rol = this.roles.filter((elem) => elem.text == i.rol)[0];
         if (!this.rol) {
            this.rol = {
               value: "",
               text: "",
            };
         }
      },
      input: function (e) {
         this.$emit("input", {
            id: this.id,
            username: this.username,
            nombre: this.nombre,
            telefono: this.telefono,
            rfc: this.rfc,
            email: this.email,
            estatus: this.estatus,
            articulo_id: this.articulo_id,
            rol: this.rol.text,
         });
      },
   },
   computed: {
      isEdit() {
         if (this.id) {
            return true;
         } else {
            return false;
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.name && errors.push("Debe contener caracteres alfabeticos");
         return errors;
      },
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El username es requerido");
         !this.$v.username.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.username.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.username.alphaNum && errors.push("Debe contener caracteres alfanumericos");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         !this.$v.password.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.password.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.required && errors.push("El telefono es requerido");
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
      rfcErrors() {
         const errors = [];
         if (!this.$v.rfc.$dirty) return errors;
         !this.$v.rfc.required && errors.push("Requerido");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.email && errors.push("Debe ser un email valido");
         !this.$v.email.required && errors.push("El email es requerido");
         !this.$v.email.maxLength && errors.push("El maximo posible es de 320");
         return errors;
      },
      estatusErrors() {
         const errors = [];
         if (!this.$v.estatus.$dirty) return errors;
         !this.$v.estatus.required && errors.push("Requerido");
         return errors;
      },
      rolErrors() {
         const errors = [];
         if (!this.$v.rol.$dirty) return errors;
         !this.$v.rol.required && errors.push("Requerido");
         return errors;
      },
      articulo_idErrors() {
         const errors = [];
         if (!this.$v.articulo_id.$dirty) return errors;
         !this.$v.articulo_id.required && errors.push("Requerido");
         !this.$v.articulo_id.is_integer && errors.push("Debe ser un valor entero");
         !this.$v.articulo_id.absNum && errors.push("Debe ser un valor positivo o cero");
         !this.$v.articulo_id.isNumber && errors.push("Debe ser un numero valido");
         return errors;
      },
   },
};
</script>
