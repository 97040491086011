<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-text-field
                  @input="
                     () => {
                        input();
                        $v.nombre.$touch();
                     }
                  "
                  v-model="nombre"
                  label="Nombre"
                  :error-messages="nombreErrors"
                  required
                  @blur="$v.nombre.$touch()"
               ></v-text-field>
               <v-select
                  item-text="text"
                  item-value="value"
                  v-model="permisos"
                  :items="allpermisos"
                  label="Permisos"
                  multiple
                  chips
                  :return-object="true"
                  @input="
                     () => {
                        input();
                        $v.permisos.$touch();
                     }
                  "
                  :error-messages="permisosErrors"
                  required
                  @blur="$v.permisos.$touch()"
               >
                  <template #selection="{ item }">
                     <v-chip color="secondary" text-color="accent">{{ item.text }}</v-chip>
                  </template>
               </v-select>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(40),
         minLength: minLength(5),
      },
      permisos: { required },
   },
   props: {
      value: Object,
      title: String,
   },
   model: {
      prop: "value",
      event: "input",
   },
   data: function () {
      return {
         id_rol: this.value.id_rol,
         nombre: this.value.nombre,
         permisos: this.value.permisos,
         dialog: false,
         isloading: false,
         allpermisos: [],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertRol", {
                     nombre: this.nombre,
                     permisos: this.savePermisos(),
                  });
               } else {
                  res = await this.$store.dispatch("updateRol", {
                     id_rol: this.id_rol,
                     nombre: this.nombre,
                     permisos: this.savePermisos(),
                  });
               }
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.id_rol = i.id_rol;
         this.nombre = i.nombre;
         let rights = [];
         this.$store.state.permisos.forEach(function (val, index, arr) {
            rights.push({
               value: val.id_permiso,
               text: val.permiso.replaceAll("_", " "),
            });
         });
         this.allpermisos = rights;
         this.permisos = [];
         if (i.permisos.length > 0) {
            let elements = [];
            i.permisos.forEach(function (val, index, arr) {
               elements.push(rights.filter((elem) => elem.value == val.id_permiso)[0]);
            });
            this.permisos = elements;
         }
      },
      input: function (e) {
         this.$emit("input", {
            nombre: this.nombre,
            permisos: this.outerPermisos(),
         });
      },
      outerPermisos: function () {
         let rights = [];
         this.permisos.forEach(function (val, index, arr) {
            rights.push({
               id_permiso: val.value,
               permiso: val.text.replace(" ", "_"),
            });
         });
         return rights;
      },
      savePermisos: function () {
         let rights = [];
         this.permisos.forEach(function (val, index, arr) {
            rights.push(val.value);
         });
         return rights;
      },
   },
   computed: {
      isEdit() {
         if (this.id_rol) {
            return true;
         } else {
            return false;
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      permisosErrors() {
         const errors = [];
         if (!this.$v.permisos.$dirty) return errors;
         !this.$v.permisos.required && errors.push("Requerido");
         return errors;
      },
   },
};
</script>
