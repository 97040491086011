var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('form',[_c('v-text-field',{attrs:{"label":"Nombre","error-messages":_vm.nombreErrors,"required":""},on:{"input":() => {
                     _vm.input();
                     _vm.$v.nombre.$touch();
                  },"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('v-select',{attrs:{"item-text":"text","item-value":"value","items":_vm.allpermisos,"label":"Permisos","multiple":"","chips":"","return-object":true,"error-messages":_vm.permisosErrors,"required":""},on:{"input":() => {
                     _vm.input();
                     _vm.$v.permisos.$touch();
                  },"blur":function($event){return _vm.$v.permisos.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('v-chip',{attrs:{"color":"secondary","text-color":"accent"}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.permisos),callback:function ($$v) {_vm.permisos=$$v},expression:"permisos"}})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }