<template>
   <div style="height: 100%; width: 100%">
      <v-tabs v-model="tab" align-with-title background-color="secondary">
         <v-tabs-slider></v-tabs-slider>
         <v-tab>Usuarios</v-tab>
         <v-tab>Roles</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pa-4">
         <v-tab-item>
            <div flat><Users /></div>
         </v-tab-item>
         <v-tab-item>
            <div flat><Roles/></div>
         </v-tab-item>
      </v-tabs-items>
   </div>
</template>

<script>
import Users from "../components/Usuarios/Users.vue";
import Roles from "../components/Usuarios/Roles.vue";

export default {
   name: "Usuarios",
   data: () => {
      return {
         tab: null,
      };
   },
   components: {
      Users,
      Roles,
   },
};
</script>
