var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('form',{staticClass:"d-flex flex-column flex-md-row justify-md-space-between"},[_c('div',{staticClass:"pa-2 d-flex flex-column justify-start",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"Nombre","error-messages":_vm.nombreErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.nombre.$touch();
                     },"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('v-text-field',{attrs:{"disabled":_vm.isEdit,"label":"Username","error-messages":_vm.usernameErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.username.$touch();
                     },"blur":function($event){return _vm.$v.username.$touch()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Contraseña","error-messages":_vm.passwordErrors,"required":!_vm.isEdit,"append-icon":_vm.show_password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_password ? 'text' : 'password'},on:{"input":() => {
                        _vm.input();
                        _vm.$v.password.$touch();
                     },"blur":function($event){return _vm.$v.password.$touch()},"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":"Telefono","error-messages":_vm.telefonoErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.telefono.$touch();
                     },"blur":function($event){return _vm.$v.telefono.$touch()}},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}})],1),_c('div',{staticClass:"pa-2 d-flex flex-column justify-start",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"RFC","error-messages":_vm.rfcErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.rfc.$touch();
                     },"blur":function($event){return _vm.$v.rfc.$touch()}},model:{value:(_vm.rfc),callback:function ($$v) {_vm.rfc=$$v},expression:"rfc"}}),_c('v-text-field',{attrs:{"label":"Email","error-messages":_vm.emailErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.email.$touch();
                     },"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"error-messages":_vm.articulo_idErrors,"label":"Liga","type":"number","requied":""},on:{"input":() => {
                           _vm.input();
                           _vm.$v.articulo_id.$touch();
                        },"blur":function($event){return _vm.$v.articulo_id.$touch()}},model:{value:(_vm.articulo_id),callback:function ($$v) {_vm.articulo_id=$$v},expression:"articulo_id"}}),_c('v-switch',{attrs:{"true-value":"S","false-value":"N","label":"Estatus","disabled":!_vm.isEdit},on:{"input":_vm.input},model:{value:(_vm.estatus),callback:function ($$v) {_vm.estatus=$$v},expression:"estatus"}})],1),_c('v-select',{attrs:{"item-text":"text","item-value":"value","return-object":true,"items":_vm.roles,"label":"Rol","error-messages":_vm.rolErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.rol.$touch();
                     },"blur":function($event){return _vm.$v.rol.$touch()}},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}})],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }